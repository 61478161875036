import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import StarsIcon from "@mui/icons-material/Stars";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import useServiceCategory from "../../../hooks/api/useServiceCategory";
import SelectService from "./SelectService";
import { usePaginatedServices } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/configServiceApiHook";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { ORDER_BOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";
import { toast } from "react-toastify";

function Service() {
  interface RootState {
    UploadLogo: {
      logo: string;
    };
  }

  interface ISelectedService {
    selectedService: {
      services: [
        {
          salonServicesId: number;
          startTime: string;
          cost: number;
          status: number;
          serviceName: string;
        }
      ];
      totalPrice: number;
    };
  }

  interface ISalonDetails {
    SalonDetails: {
      Salon_data: {
        name: string
      }
    }
  }

  interface ICustomerToken {
    CustomerCred: {
      token: {
          id: number;
          customerName: string;
          uuid: string;
          mobileNo: number;
          email: string;
          gender: string;
          status: string;
          termAndCondition: string;
          salonId: number | null;
          dob: string;
          statusCode: number;
      };
      isCustomer: boolean;
      statusCode: 200;
    };
  }
  interface IAuthToken {
    auth: {
      email: string;
      token: string;
      data: {
        salonId: number;
        clientId: string;
        userRole: string;
        sub: string;
        iat: number;
        exp: number;
      };
      userRole: string;
      salonId: number;
      clientId: string;
    };
  }

  const location = useLocation();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const segment = location.pathname.split("/")[3];
  const Logo = useSelector((state: RootState) => state.UploadLogo.logo);
  const SelectedService = useSelector(
    (state: ISelectedService) => state.selectedService
  );
  const CustomerDetails = useSelector(
    (state: ICustomerToken) => state.CustomerCred.token
  );
  
  const Auth = useSelector((state: IAuthToken) => state.auth);

  const SalonDetails = useSelector((state: ISalonDetails) => state?.SalonDetails?.Salon_data)
  // console.log(location, segment, "this_is_location");
  console.log("customerCred", CustomerDetails);
  console.log("AuthCred", Auth);
  // const [price, setPrice] = useState(0);

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [menu, setMenu] = useState(false);
  // const handleOpenMenu = () => setMenu(true);
  const handleCloseMenu = () => setMenu(false);

  const { serviceData } = usePaginatedServices(1, 2, false);
  const { loadingServiceCategory, serviceListData } = useServiceCategory({
    filters: { gender: segment, status: "ACTIVE" },
  });

  console.log(serviceListData, "___data___");

  const navigate = useNavigate();
  if (loadingServiceCategory || !serviceListData) return <div>Loading</div>;

  const handleCheckout = async () => {

    // console.log("working");
    if (SelectedService.services.length > 0) {
      const payload = {
        customerId: CustomerDetails.id,
        salonId: Auth?.data?.salonId,
        paidAmount: 0.0,
        totalCost: 0.0,
        discount: 0.0,
        status: "ACTIVE",
        paymentType: "CASH",
        salonServicesId: SelectedService.services.map((item) => (
          item.salonServicesId
        )),
      };
  
      // console.log("payload: ", payload);
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: ORDER_BOOK_ENDPOINT,
          data: payload,
        });

        // const storePayload = {
        //   id: response?.data?.id,
        //   customerEntity : response?.data?.customerEntity,
        //   paidAmount : response?.data?.paidAmount,
        //   totalCost : response?.data?.totalCost,
        //   discount: response?.data?.discount,
        //   token: response?.data?.token,
        //   assignedStaffIds: response?.data?.assignedStaffIds,
        //   status : response?.data?.status,
        //   paymentType : response?.data?.paymentType,
        //   serviceLogBookEntities : response?.data?.serviceLogBookEntities
        // }
        if(response?.data?.id){
          dispatch(setOrderBook({ id: response.data.id, data: response.data }));
        navigate("/customer/checkout");
        }
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
        toast.error("Failed to process order. Please try again.");
      }
    } else {
      toast.warning("Please Select a Service");
    }
  };
  

  return (
    <>
      <div className="h-screen w-full grid relative">
        <div className="h-[100px] sm:h-[150px] row-span-2 flex justify-center items-center sm:justify-start px-10">
          <img
            src={Logo}
            className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px]"
          />
        </div>

        <div className="flex justify-between items-center text-4xl px-4 text-center font-bold text-black sm:text-7xl">
          <p
            className="hidden sm:flex sm:text-3xl font-semibold items-center gap-2 cursor-default"
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon fontSize="large" /> back
          </p>
          <p
            className="sm:hidden font-semibold flex items-center gap-2 cursor-default"
            onClick={() => navigate("/")}
          >
            <KeyboardBackspaceIcon fontSize="large" />
          </p>
          <p
            className="sm:hidden font-semibold flex items-center gap-2 cursor-default"
            onClick={() => setMenu(true)}
          >
            <LocalMallIcon fontSize="large" />
          </p>
        </div>

        <div className="h-full sm:w-3/5 bg-slate-100 p-8 flex flex-col justify-between sm:overflow-hidden sm:grid">
          <div className="overflow-hidden w-full">
            <div className="py-7 ">
              <SelectService serviceListData={serviceData} type={segment}/>
            </div>
          </div>

          <div className="w-full border-2 border-gray-400 px-3 relative overflow-y-scroll hide-scrollbar rounded-lg">
            <div className="h-full w-full">
              <ServiceCard serviceListData={serviceData} type={segment} />
            </div>
          </div>
        </div>

        <div className="h-full w-2/5 bg-gray-400 p-8 hidden sm:block absolute right-0 top-0">
          <div className=" w-full h-1/3 flex items-start justify-center flex-col">
            <p className="text-4xl font-bold mb-8">
              {SalonDetails?.name} is pleased to serve you. 
            </p>
            <p className="text-black text-2xl">
              <StarsIcon className="text-black " fontSize="medium" /> 4.89 (5.0
              M Orders)
            </p>
          </div>

          <div className="bg-slate-100 rounded-lg w-full h-2/3 px-2 py-3 sm:grid overflow-hidden">
            <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
              Cart
            </div>
            <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
              <div className="h-full w-full p-1 rounded-md text-center">
                {SelectedService.services.length > 0 ? (
                  SelectedService.services.map((item, index) => (
                    <div
                      className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                      key={index}
                    >
                      <p className="w-2/5 h-full text-wrap pl-2">
                        {item.serviceName}
                      </p>
                      <p className="text-wrap w-1/5 h-full">
                        &#8377; {item.cost}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 text-xl italic">Cart is Empty</p>
                )}
              </div>
            </div>
            <div className=" w-full flex justify-start items-start pt-4">
              <button
                className="w-full rounded-md py-4 flex justify-between px-3 opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform  hover:scale-105 text-white"
                onClick={handleCheckout}
              >
                <p>Checkout</p>
                {SelectedService?.totalPrice && (
                  <p>&#8377; {SelectedService.totalPrice}</p>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-full h-full flex justify-center items-center"
        >
          <Box className="bg-slate-200 h-2/3 sm:w-2/3 mx-1 overflow-y-scroll hide-scrollbar rounded-lg">
            <div className="h-1/2 m-6 relative text-start border-b-slate-600 border-b-2 justify-center item-center  hidden sm:flex">
              <div className="w-1/2 h-full flex-col items-center gap-4">
                <div className="w-full h-5/6 flex flex-wrap items-start">
                  <div className="w-1/2 h-1/2 p-4 ">
                    <img
                      src="/Frame25.png"
                      className="shadow-lg img rounded-md"
                    />
                  </div>
                  <div className="w-1/2 h-1/2 p-4 ">
                    <img
                      src="/Frame24.png"
                      className="shadow-lg img rounded-md"
                    />
                  </div>
                  <div className="w-1/2 h-1/2 p-4 ">
                    <img
                      src="/Frame23.png"
                      className="shadow-lg img rounded-md"
                    />
                  </div>
                </div>
                <div className="w-full h-1/6">
                  <button className="px-12 shadow-lg py-2 mx-4 text-center text-xl font-semibold bg-green-500 text-white rounded-lg">
                    Add
                  </button>
                </div>
              </div>
              <div className="w-1/2 h-full p-6">
                <div className="w-full h-5/6 flex flex-wrap items-start">
                  <div className="w-full h-full">
                    <img
                      src="/Frame28.png"
                      className="shadow-md img rounded-md"
                    />
                  </div>
                </div>
                <div className="w-full h-1/6 flex justify-end items-center">
                  <p className="text-3xl font-semibold text-black rounded-lg">
                    &#8377; 239
                  </p>
                </div>
              </div>
            </div>
            <div className="h-1/2 m-8 text-md text-start">
              <p className="text-2xl font-semibold">About the process</p>
              <br />
              <p className="text-xl font-medium">1. Consultation</p>
              <p className="ml-5">
                Professional understands customer needs and hair condition to
                suggest suitable options
                <br />
              </p>
              <p className="text-xl font-medium">2. Set-up</p>
              <p className="ml-5">
                Sanitization of tools and placement of cape, mirror, floor sheet
                <br />
              </p>
              <p className="text-xl font-medium">3. Parting & sectioning</p>
              <p className="ml-5">
                Detangling of hair followed by dividing it into small sections
                <br />
              </p>
              <p className="text-xl font-medium">4. Hair cut</p>
              <p className="ml-5">
                Spraying of water, followed by cutting of hair as per the
                desired hair style with the cape on
                <br />
              </p>
              <p className="text-xl font-medium">5. Confirmation</p>
              <p className="ml-5">
                Rechecking of the output with customer and working on
                suggestions (if any) for desired results.
                <br />
              </p>
              <p className="text-xl font-medium">6. Clean up</p>
              <p className="ml-5">
                Removal of all the hair strands, sanitisation of tools and clean
                up of the surrounding area
              </p>
            </div>
          </Box>
        </Modal> */}

        <Modal
          open={menu}
          onClose={handleCloseMenu}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-full h-full flex justify-center items-center"
        >
          {/* <Box className="bg-black/[0.6] overflow-y-scroll hide-scrollbar relative"> */}

          <div className="h-full w-full bg-gray-400 p-8 overflow-y-scroll hide-scrollbar relative">
            <div className=" w-full flex items-start m-4 flex-col">
              <p className="font-bold text-xl">{SalonDetails?.name} is pleased to serve you.</p>
              <p className="text-black text-xs">
                <StarsIcon className="text-black " fontSize="small" /> 4.89 (5.0
                M Orders)
              </p>
            </div>

            <div className="bg-slate-100 rounded-lg w-full px-2 py-3 overflow-hidden">
            <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
              Cart
            </div>
            <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
              <div className="h-full w-full p-1 rounded-md text-center">
                {SelectedService.services.length > 0 ? (
                  SelectedService.services.map((item, index) => (
                    <div
                      className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                      key={index}
                    >
                      <p className="w-2/5 h-full text-wrap pl-2">
                        {item.serviceName}
                      </p>
                      <p className="text-wrap w-1/5 h-full">
                        &#8377; {item.cost}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 text-xl italic">Cart is Empty</p>
                )}
              </div>
            </div>
            <div className=" w-full grid gap-4">
              <button
                className="w-full rounded-md py-4 flex justify-between px-3 text-lg font-medium opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform hover:opacity-0 hover:scale-150 text-white"
                onClick={handleCheckout}
              >
                <p>Checkout</p>
                {SelectedService?.totalPrice && (
                  <p>&#8377; {SelectedService.totalPrice}</p>
                )}
              </button>
              <button
                className="w-full rounded-md py-4 px-3 opacity-100 bg-gray-300 text-lg font-medium transition duration-500 transform hover:opacity-0 hover:scale-150"
                onClick={() => setMenu(false)}
              >
                Close Cart
              </button>
            </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Service;
