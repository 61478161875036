import { createSlice } from "@reduxjs/toolkit";

let initialAvailableStaff = []

export const StaffAvailableSlice = createSlice({
  initialState: initialAvailableStaff,
  name: "StaffAvailable",
  reducers: {
    setStaffAvailableForService: (state, action) => {
      state = [...action.payload];
    },
    addStaffAvailableForService: (state, action) => {
      state.push(action.payload);
    },
    removeAllStaffAvailableForService: (state, action) => {
      return initialAvailableStaff;
    },
  },
});

export const {
  setStaffAvailableForService,
  addStaffAvailableForService,
  removeAllStaffAvailableForService,
} = StaffAvailableSlice.actions;

export default StaffAvailableSlice.reducer;
