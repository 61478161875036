import { useNavigate } from "react-router-dom";
import { loginAdmin, salonLogin } from "../../../api/salon-api/auth-service";
import { IAdminLogin } from "./admin.d.ts/adminLogin";
import { useDispatch, useSelector } from "react-redux";
import { IData, setLogin } from "../../../redux/feature/authSlice";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../helper/Common/constant";
import { FieldIcon } from "../../../components/Mui/Formik/FieldIcon";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { IconButton, InputAdornment, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SalonLogo } from "../../../assets/img";
import { jwtDecode } from "jwt-decode";
import { ROLE } from "../../../Routes/routeList";
import { isValidEmail } from "../../../helper/Common/Validators";
import { Notification } from "../../../firebase/Notifaction";
import { requestForToken } from "../../../firebase/firebase";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IRes {
  token: string;
  userRole: string;
  salonId: number;
  clientId: string;
}

interface RootState {
  common: {
    salonId: string;
  };
}

export default function EmployeeLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salonId = useSelector((state: RootState) => state?.common?.salonId);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const adminLogin = async (data: IAdminLogin) => {
    const { email, password } = data;
    const isSalon = isValidEmail(email);
    if (!isSalon) {
      try {
        const loginData = await salonLogin(email, password);
        const { token } = loginData;
        const objData = jwtDecode(token) as IData;
        toast("Successfully Logged in!");
        localStorage.setItem("auth", JSON.stringify({ token, key: password }));
        dispatch(setLogin({ email, ...loginData }));
        if (objData.userRole === ROLE.ADMIN) {
          navigate("/adminDashboard");
        }
        if (ROLE.SUPER_ADMIN === objData.userRole) {
          navigate("/salonOnboardingForm");
        }
        if (ROLE.SALON === objData.userRole) {
          navigate("/customer/signup");
        }
        return;
      } catch (error) {
        toast("Wrong Credentials! Please enter valid credentials");
        console.error(error);
        return;
      }
    }

    try {
      const fcmToken = (await requestForToken()) as string;
      const loginData = await loginAdmin(email, password, salonId, fcmToken);
      const { token } = loginData;
      const objData = jwtDecode(token) as IData;
      toast("Successfully Logged in!");
      localStorage.setItem("auth", JSON.stringify({ token, key: password }));
      dispatch(setLogin({ token, email }));
      if (objData.userRole === ROLE.ADMIN) {
        navigate("/adminDashboard");
      }
      if (ROLE.SUPER_ADMIN === objData.userRole) {
        navigate("/salonOnboardingForm");
      }
      if (ROLE.SALON === objData.userRole) {
        navigate("/customer/signup");
      }
    } catch (error) {
      toast("Wrong Credentials! Please enter valid credentials");
      console.error(error);
    }
  };

  const adminLoginInitialValues = {
    email: "",
    password: "",
  };

  const validationSchemaLoginAdmin = Yup.object().shape({
    email: Yup.string().required("Field is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(4, "Should be 4 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });

  // auto login
  useEffect(() => {
    const token = localStorage.getItem("auth");
    if (token) {
      const data = JSON.parse(token);
      const objData = jwtDecode(data.token) as IData;
      const email = objData?.email;
      const isSalon = isValidEmail(email);
      const password = data.key;
      if (!isSalon) {
        adminLogin({ email: objData?.clientId || "", password });
        return;
      }
      adminLogin({ email, password });
    }
  }, []);

  const imgUrl =
    "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <>
      <Notification />
      <div
        className="bg-slate-400 h-screen w-screen flex items-center justify-center"
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-gray-100 bg-opacity-55 backdrop-blur-sm p-8 rounded-md w-96 shadow-md">
          <div className="mb-10 flex justify-center">
            <img
              src={SalonLogo}
              className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] my-5"
            />
          </div>
          <Formik
            initialValues={adminLoginInitialValues}
            validationSchema={validationSchemaLoginAdmin}
            onSubmit={adminLogin}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form name="adminLogin">
                  <Stack spacing={2}>
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter your email or ID"
                      label="Email or ID"
                      type="text"
                      name={"email"}
                      id={"email"}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      InputProps={FieldIcon(<EmailIcon />)}
                    />
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter your password"
                      label="Password"
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                              <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <LoadingButton
                    loading={formikProps.isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3 }}
                  >
                    Login
                  </LoadingButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
