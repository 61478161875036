import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  SERVICE_CATEGORY_ALL_ENDPOINT,
  SERVICE_CATEGORY_ENDPOINT,
} from "../../../../../api/salon-api/salon-service";
import {
  IServiceCategory,
  IServiceCategoryData,
} from "../ConfigureServices/types/configService";
import {
  setServiceCategory,
  setUpdateServiceCategory,
} from "../../../../../redux/feature/salonServiceSlice";
import { RState } from "../../../../../redux/feature/slice";
import { toast } from "react-toastify";

export const usePaginatedServicesCategory = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)

  const fetchService = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = all
        ? {}
        : {
            page: currentPage,
            size: pageSize,
            salonId: SalonDetails?.id
          };

      const response = await axiosPrivate.post<IServiceCategoryData>(
        SERVICE_CATEGORY_ALL_ENDPOINT,
        params
      );

      if (response.status === 200) {
        dispatch(setServiceCategory(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchService();
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
  };
};

export const useUpdateServicesCategory = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)

  const updateServiceCategory = async (data: IServiceCategory) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.put<IServiceCategory>(
        `${SERVICE_CATEGORY_ENDPOINT}/${data.id}`,
        { ...data, salonId: SalonDetails?.id }
      );

      if (response.status === 200) {
        dispatch(setUpdateServiceCategory(data));
        setStatus(true);
        toast.success("Service category updated Successfully")
      } else {
        setError(new Error("Failed to update service"));
        toast.error("Unable to update service category")
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateServiceCategory,
    status
  };
};
