import React from 'react'
import SectionLeft from './SectionLeft'
import SectionRight from './SectionRight'
import PopularCards from './PopularCards'
import GroupCards from './GroupCards'

function Category() {
    return (
        <div className='grid grid-rows-12 relative'>
            <div className='row-span-2 h-[200px] sm:h-[800px] sm:row-span-4 xl:h-[1200px]'>
                <div className='flex flex-center'>
                <SectionLeft/>
                <SectionRight />
                </div>
            </div>
            <div className=' flex items-center row-span-2 h-[200px] sm:h-[400px] sm:row-span-2 xl:row-span-1 relative'><PopularCards/></div>
            <div className=' row-span-8 h-[800px]'><GroupCards /></div>
        </div>
    )
}

export default Category
