import React from "react";
import Category from "../../../components/CustomerComponent/Categories/Category";
import Header from "../../../components/CustomerComponent/Header/Header";

function Dashboard() {

  return (
    <>
    <div className="h-screen w-full bg-slate-200 grid grid-rows-12">
      <div className="bg-slate-800 row-span-3 overflow-hidden">
        <Header />
      </div>
      <div className="row-span-9">
        <div className="h-full w-full overflow-y-scroll hide-scrollbar">
        <Category />

        </div>
      </div>

    </div>

    </>
  );
}

export default Dashboard;
