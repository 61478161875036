import React from "react";
import { Form, Formik } from "formik";
import { Button, Stack } from "@mui/material";
import { CustomizedDialog } from "../../../../../../components/Mui/feedback/Dialog";
import { IOpenInventoryForm } from "../InventoryTable";
import {
  useCreateInventory,
  useUpdateInventory,
} from "../../../../../../hooks/api/inventory-service/useSalonInventoryController";
import { InventoryContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { inventoryInitialValue } from "../formModal/inventoryInitialValue";
import { validationSchemaInventory } from "../formModal/validationSchemaInventory";
import InventoryFormUi from "./InventoryFormUi";
import InventoryIcon from "@mui/icons-material/Inventory";
import { toast } from "react-toastify";

interface IInventoryFormProps {
  openInventoryForm: IOpenInventoryForm;
  handleCloseInventoryForm: () => void;
}

const InventoryForm = ({
  openInventoryForm,
  handleCloseInventoryForm,
}: IInventoryFormProps) => {
  const { createInventory } = useCreateInventory();
  const { updateInventory } = useUpdateInventory();

  const updateInventoryFn = async (values: InventoryContent) => {
    const response = await updateInventory(values);
    if (response.status === 200) {
      handleCloseInventoryForm();
      toast.success("Inventory updated successfully");
    } else {
      toast.error("Something went wrong");
    }
  };
  const createInventoryFn = async (values: InventoryContent) => {
    const response = await createInventory(values);

    if (response.status > 200 && response.status < 300) {
      handleCloseInventoryForm();
      toast.success("Inventory created successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = async (values: InventoryContent) => {
    console.log("working handlesubmit", values)
    if (openInventoryForm.mode === "EDIT") {
      await updateInventoryFn({ ...values, id: openInventoryForm.data?.id, status: "ACTIVE" });
      return;
    }
    if (openInventoryForm.mode === "ADD") {
      await createInventoryFn({
        ...values,
        status: "ACTIVE"
      });
      return;
    }
  };

  return (
    <div>
      <CustomizedDialog
        openDialog={openInventoryForm?.open}
        handleCloseDialog={handleCloseInventoryForm}
        title={
          openInventoryForm?.mode === "EDIT"
            ? "Edit Inventory"
            : "Add Inventory"
        }
        disableClose={false}
        icon={<InventoryIcon />}
        maxWidth="sm"
        isActionButtonRequired={false}
        actionButtons={<></>}
      >
        <Formik
          initialValues={inventoryInitialValue(openInventoryForm)}
          validationSchema={validationSchemaInventory}
          onSubmit={handleSubmit}
          // onSubmit={(values) => console.log(values)}
          enableReinitialize
          validateOnChange={true}
        >
          {(formikProps) => {
            return (
              <Form id="add-product-form">
                <InventoryFormUi formikProps={formikProps} />
                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                  <Button type="submit" variant="contained">
                    {openInventoryForm.mode === "ADD" ? "Save" : "Update"}
                  </Button>
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </CustomizedDialog>
    </div>
  );
};

export default InventoryForm;
