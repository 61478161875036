import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Navigator from "../../../components/SalonStaffComponent/Navigator";
import { Outlet } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { SALON_ONBOARDING_ENDPOINT } from "../../../api/salon-api/salon-service"
import { setDetails } from "../../../redux/feature/salonDetailsSlice";
import { setLogo } from "../../../redux/feature/uploadLogoSlice";
import { setPaymentQR } from "../../../redux/feature/uploadPaymentSlice";
import { setSalonId } from "../../../redux/feature/commonSlice";
import { IMG_URL } from "../../../constants/baseURL";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("lg")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  const [drawerWidth, setDrawerWidth] = React.useState(256);
  const { isDrawerOpen } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const SalonDetails = useSelector((state) => state.SalonDetails)
  const Auth = useSelector((state) => state.auth)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const GetDetails = async (id = Auth.data.salonId) => {
    try {
      if(!SalonDetails.length > 0){
        const response = await axiosPrivate({
          method: 'GET',
          url: `${SALON_ONBOARDING_ENDPOINT}/${id}`,
        });
        dispatch(setDetails(response.data));
        dispatch(setSalonId(SalonDetails.Salon_data.id))
        dispatch(setLogo(`${IMG_URL.BASE_SERVICE_IMG}${response.data.logoS3Path}`))
        dispatch(setPaymentQR(`${IMG_URL.BASE_SERVICE_IMG}${response.data.paymentS3Path}`))
      }
    } catch (error) {
      console.error('Error fetching Salon Details:', error);
    }
  }


  React.useEffect(() => {
    GetDetails()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          display={isDrawerOpen ? "none" : "flex"}
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        >
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{
              display: {
                md: isDrawerOpen && "block",
                sm: isDrawerOpen && "none",
                xs: isDrawerOpen && "none",
              },
            }}
            setDrawerWidth={setDrawerWidth}
            isDrawerOpen={isDrawerOpen}
          />
        </Box>
        <Outlet />
      </Box>
    </ThemeProvider>
  );
}
