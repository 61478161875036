
import React, { useState, useEffect } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { IServiceDataLProps } from "./SelectService";
import { IMG_URL } from "../../../constants/baseURL";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedService,
  removeSelectedService,
} from "../../../redux/feature/selectedService";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON } from "../../../api/salon-api/log-book-service";
import { RState } from "../../../redux/feature/slice";
import NoStaffModal from "./NoStaffModal";

// Utility function to format date
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

interface ISelectedService {
  salonServicesId: number;
  cost: number;
  startTime: string;
  status: number;
  serviceName: string;
}

interface RootState {
  selectedService: {
    services: ISelectedService[];
  };
}

const ServiceCard = ({ serviceListData, type }: IServiceDataLProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const selectedServices = useSelector(
    (state: RootState) => state.selectedService.services
  );
  const SalonData = useSelector(
    (state: RState) => state.SalonDetails?.Salon_data
  );

  const [addedServices, setAddedServices] = useState<number[]>([]);
  const axiosPrivate = useAxiosPrivateLogBook();

  const handleOpen = () => setOpen(!open);

  useEffect(() => {
    const serviceIds = selectedServices.map(
      (service) => service.salonServicesId
    );
    setAddedServices(serviceIds);
  }, [selectedServices]);

  const handleRemoveServices = (salonServiceId: number | undefined) => {
    if (salonServiceId !== undefined) {
      dispatch(removeSelectedService(salonServiceId));
    }
  };

  const handleSelectedService = async (
    salonServiceId: number | undefined,
    serviceName: string,
    price: number
  ) => {
    if (salonServiceId !== undefined) {
      const payload = {
        salonServicesId: salonServiceId,
        cost: price,
        startTime: formatDate(new Date()), // Use the utility function here
        status: 1,
        serviceName: serviceName,
      };
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: `${CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON}/${salonServiceId}`,
        });
        if (response.data) {
          dispatch(addSelectedService(payload));
        } else {
          // toast.warning("This Service is currrently unavailable !");
          handleOpen();
        }
      } catch (error) {
        console.error("Error Checking Available Staff for service", error);
        toast.error("Failed to process. Please try again.");
      }
    }
  };

  return (
    <div>
      {serviceListData?.content
        .filter(
          (item) =>
            item.status === "ACTIVE" &&
            (SalonData?.salonTypeStatus.toUpperCase() === "UNISEX"
              ? item.gender.toLocaleUpperCase() === type?.toUpperCase()
              : item.gender.toUpperCase() === SalonData?.salonTypeStatus)
        )
        .map((item, index) => {
          const {
            id,
            imageS3path,
            serviceName,
            price,
            description,
            avgServeTime,
          } = item;
          const isAdded = id !== undefined && addedServices.includes(id);

          return (
            <div
              className="p-2 w-full bg-4 rounded-lg flex-col sm:flex-row flex sm:overflow-hidden my-6"
              key={index}
            >
              <div className="w-full sm:w-[250px] h-[250px] p-4 sm:p-6 relative">
                {
                 imageS3path ?  
                  <img
                  className="img rounded-sm shadow-sm object-cover"
                  src={`${IMG_URL.BASE_SERVICE_IMG}${imageS3path}`}
                  alt={serviceName}
                /> :
                <img
                  className="img rounded-sm shadow-sm object-cover"
                  src="/no-service-image.png"
                  alt={serviceName}
                />
              
              }
              </div>
              <div className="sm:w-1/2 flex flex-col justify-between items-start p-8 text-wrap">
                <h3 className="text-2xl font-semibold">{serviceName}</h3>
                <div>
                  <p className="text-xs pl-1">{description}</p>
                  <p className="text-md font-light my-1">
                    <AccessTimeFilledIcon
                      className="text-blue-700"
                      fontSize="small"
                    />{" "}
                    {avgServeTime} minutes
                  </p>
                  <p className="font-bold ml-1">&#8377; {price}</p>
                </div>
                {isAdded ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveServices(id)}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      handleSelectedService(id, serviceName, price)
                    }
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      <NoStaffModal open={open} handleOpen={handleOpen} />
    </div>
  );
};

export default ServiceCard;
