import axios from "axios";
import { BASE_URL } from "../../constants/baseURL";
export const AUTH_ENDPOINT = "auth/login";
export const AUTH_STAFF_ENDPOINT = "auth/staffLogin";
export const AUTH_SALON_ENDPOINT = "auth/salonLogin";

export const headers = {
  "Content-Type": "application/json",
};

/**
 * Sends a POST request to the authentication service to log in an admin user.
 *
 * @param {string} email - The email of the admin user.
 * @param {string} password - The password of the admin user.
 * @param {string} salonId - The ID of the salon.
 * @param {boolean} isAdmin - Flag indicating if the user is an admin.
 * @return {Promise<any>} A promise that resolves to the response data if the request is successful,
 * or rejects with an error if the request fails.
 */
export const loginAdmin = async (
  email: string,
  password: string,
  salonId: string,
  fcmToken?: string
): Promise<any> => {
  try {
    const data: {
      email: string;
      password: string;
      // salonId: string;
      fcmToken?: string;
    } = {
      email,
      password,
      // salonId,
      fcmToken: fcmToken ? fcmToken : "",
    };

    const response = await axios.post(
      BASE_URL.AUTH_SERVICE_URL + AUTH_ENDPOINT,
      data,
      { headers }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error during loginAdmin:", error);
    throw error;
  }
};

interface Ipayload {
  number: number,
  password: string
}

export const loginEmployee = async (payload: Ipayload) => {

  console.log(payload);

  return axios({
    method: "post",
    headers,
    url: BASE_URL.AUTH_SERVICE_URL + AUTH_STAFF_ENDPOINT,
    data: {...payload},
  })
    .then((response) => {
      // Check if the response status is OK (200-299)
      if (response.status >= 200 && response.status < 300) {
        // Axios automatically parses JSON response
        return response.data;
      } else {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    })
    .catch((error) => {
      // Axios automatically wraps non-2xx HTTP responses into an error
      throw error;
    });
};

interface IRes {
  token: string;
  userRole: string;
  salonId: number;
  clientId: string;
}

export const salonLogin = async (
  clientId: string,
  clientCredentials: string
) => {
  return axios({
    method: "post",
    headers,
    url: BASE_URL.AUTH_SERVICE_URL + AUTH_SALON_ENDPOINT,
    data: {
      clientId: clientId,
      clientCredentials: clientCredentials,
    },
  })
    .then((response) => {
      // Check if the response status is OK (200-299)
      if (response.status >= 200 && response.status < 300) {
        // Axios automatically parses JSON response
        return response.data as IRes;
      } else {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    })
    .catch((error) => {
      // Axios automatically wraps non-2xx HTTP responses into an error
      throw error;
    });
};
