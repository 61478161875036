import React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import SalonDetailsForm from "./SalonDetailsForm";
import UploadLogoForm from "./UploadLogoForm";
import CreateAdminForm from "./CreateAdminForm";

const steps = ['Salon Details', 'Upload Logo', 'Create Admin'];

function SalonOnboardingForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate()

  const handleFinish = () => {
    window.localStorage.removeItem('auth')
    navigate('/')
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container component="main" maxWidth="md" sx={{position: "relative"}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Salon Onboarding
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 && <SalonDetailsForm handleNext={handleNext} />}
                {activeStep === 1 && <UploadLogoForm  handleNext={handleNext} handleBack={handleBack} />}
                {activeStep === 2 && <CreateAdminForm handleFinish={handleFinish} handleBack={handleBack}/>}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default SalonOnboardingForm;
