import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../useAxiosPrivate";
import { useEffect, useState } from "react";
import { RState } from "../../../redux/feature/slice";
import { setLogBookServiceData } from "../../../redux/feature/logBookServiceSlice";
import { ILogBook } from "./logbook";
import { LOGBOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";

export const usePaginatedLogBookServices = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const logBookServiceData = useSelector(
    (state: RState) => state.logBookService.allLogBook
  );
  const SalonDetails = useSelector((state: RState) => state.SalonDetails?.Salon_data)
  // const sdfdsf = serviceData.

  const fetchLogBookService = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = !all
        ? {
            page: currentPage,
            size: pageSize,
            salonId: SalonDetails?.id
          }
        : {};

      const response = await axiosPrivate.post<ILogBook>(
        LOGBOOK_ENDPOINT,
        params
      );

      if (response.status === 200) {
        dispatch(setLogBookServiceData(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      currentPage !== logBookServiceData?.page ||
      pageSize !== logBookServiceData?.totalElements
    ) {
      fetchLogBookService();
    }
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
    logBookServiceData,
  };
};
