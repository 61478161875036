import React from "react";
import { IOpenProductForm } from "../ProductTable";
import { CustomizedDialog } from "../../../../../../components/Mui/feedback/Dialog";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { Form, Formik } from "formik";
import { productInitialValue } from "../formModal/initialValueProducts";
import { validationSchemaProducts } from "../formModal/validationProduct";
import ProductFormUI from "./ProductFormUI";
import { Button, Stack } from "@mui/material";
import {
  useCreateProduct,
  useUpdateProduct,
} from "../../../../../../hooks/api/inventory-service/useAllProduct";
import { IProductsContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { usePaginatedVendorController } from "../../../../../../hooks/api/salonService/vendorController/useAllVendor";

interface IProductFormProps {
  openProductForm: IOpenProductForm;
  handleCloseProductForm: () => void;
}

const ProductForm = ({
  openProductForm,
  handleCloseProductForm,
}: IProductFormProps) => {
  const { createProduct, isLoading } = useCreateProduct();
  const { updateProduct } = useUpdateProduct();
  const { vendorData } = usePaginatedVendorController(0, 0, true);

  const updateProductFn = async (values: IProductsContent) => {
    await updateProduct(values);
    handleCloseProductForm();
  };
  const createProductFn = async (values: IProductsContent) => {
    await createProduct(values);
    handleCloseProductForm();
  };

  const handleSubmit = async (values: IProductsContent) => {
    // const SelectedVendor = vendorData?.content?.filter((item) => item.id === values?.vendorIdList?.[0])
    const requestBody = {...values};
    if (openProductForm.mode === "EDIT") {
      await updateProductFn({
        ...requestBody,
        id: openProductForm?.data?.id});
      return;
    }
    if (openProductForm.mode === "ADD") {
      await createProductFn(requestBody);
      return;
    }
    console.log("values", values)
    console.log("requestBody", requestBody)
  };

  return (
    <div>
      <CustomizedDialog
        openDialog={openProductForm?.open}
        handleCloseDialog={handleCloseProductForm}
        title={
          openProductForm?.mode === "EDIT" ? "Edit Product" : "Add Product"
        }
        disableClose={isLoading}
        icon={<ShoppingCartCheckoutIcon />}
        maxWidth="sm"
        isActionButtonRequired={false}
        actionButtons={<></>}
      >
        <Formik
          initialValues={productInitialValue(openProductForm)}
          validationSchema={validationSchemaProducts}
          onSubmit={handleSubmit}
          // enableReinitialize={true}
          validateOnChange={true}
        >
          {() => (
            <Form id="add-product-form">
              <ProductFormUI openProductForm={openProductForm} />
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <Button type="submit" variant="contained">
                  {openProductForm.mode === "ADD" ? "Save" : "Update"}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </CustomizedDialog>
    </div>
  );
};

export default ProductForm;
