import React from 'react'
import Data from "../../../Data/categories/PopularCards.json"

function PopularCards() {
  return (
    <div className='w-full h-72 mt-8 p-2 overflow-x-auto hide-scrollbar absolute flex items-center gap-8 text-white'>
        {Data.map((item,index) => 
        <div key={index} className='sm:w-96 w-80 h-44 sm:h-56 flex flex-shrink-0 rounded-xl overflow-hidden text-wrap shadow-xl'>
            <div className='w-2/5 h-full flex flex-col justify-center items-center relative pr-2' style={{backgroundColor: item.color1}}>
                <p className='absolute top-0 left-auto px-2 py-1 rounded-b-md shadow-md italic text-xs md:text-md' style={{backgroundColor: item.color2}}>{item.tag}</p>
                <p className='text-sm sm:text-xl font-semibold mb-1 italic ml-4'>{item.heading}</p>
                <p className='text-xs text-gray-200 md:text-sm font-normal mb-6 '>{item.belongCategory}</p>
                <button className='px-5 py-1 rounded-md shadow-md' style={{backgroundColor: item.color2}}>Add</button>
            </div>
            <div className='w-3/5 h-full'><img src={item.imgSrc} className='img object-cover'/></div>
        </div>)}
    </div>
  )
}

export default PopularCards
