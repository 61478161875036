import SignUp from "../pages/Customer/Signup/SignUp";
// import StaffLogin from "../components/SalonStaffComponent/StaffLogin";
import { createBrowserRouter } from "react-router-dom";
// import EmployeeLogin from "../pages/SalonStaff/Admin/AdminLogin";
import {
  AdminDashboard,
  AdminLogin,
  Advertisement,
  ConfigureEmployees,
  ConfigureServices,
  EmployeeFeedback,
  EmployeeLogin,
  EmployeeManagement,
  InventoryManagement,
  Payouts,
  PrinterSettings,
  Reports,
  SalonOnboardingForm,
  SystemManagement,
  TaxConfiguration,
  ThumbScanner,
  TimeSheets,
  UpdateLogo,
  SalonConfiguration,
} from "../pages";
import Dashboard from "../pages/Customer/Dashboard/Dashboard";
import Service from "../pages/Customer/Service/Service";
import Checkout from "../pages/Customer/Checkout/Checkout";
import Logout from "../pages/Customer/Logout/Logout";
import ServiceCategory from "../pages/SalonStaff/Admin/SystemManagement/ServiceCategory/ServiceCategory";

import Products from "../pages/SalonStaff/Admin/InventoryManagement/products";
import Payment from "../pages/SalonStaff/Admin/SystemManagement/PaymentConfiguration/Payment";
import Venders from "../pages/SalonStaff/Admin/InventoryManagement/Venders";
import SalonInventory from "../pages/SalonStaff/Admin/InventoryManagement/SalonInventory";
import DayEndSummary from "../pages/SalonStaff/Admin/Reports/DayEndSummary";
import { PlatformAd } from "../pages/SalonStaff/Admin/Advertisement/platformAd-ad";

// const NotFound = () => {
//   return (
//     <div>
//       <h1>Not Found</h1>
//     </div>
//   );
// };

export const ROLE = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  STAFF: "STAFF",
  SALON: "SALON",
};

const SUPER_ADMIN_ROUTES = [
  {
    path: "/salonOnboardingForm",
    element: <SalonOnboardingForm />,
  },
];

const ADMIN_ROUTES = [
  {
    path: "/adminDashboard",
    element: <AdminDashboard />,
    children: [
      {
        path: "/adminDashboard",
        element: <EmployeeManagement />,
        children: [
          {
            path: "/adminDashboard/",
            element: <ConfigureEmployees />,
          },
          {
            path: "/adminDashboard/employeeFeedback",
            element: <EmployeeFeedback />,
          },
          {
            path: "/adminDashboard/payouts",
            element: <Payouts />,
          },
          {
            path: "/adminDashboard/timeSheets",
            element: <TimeSheets />,
          },
        ],
      },
      {
        path: "/adminDashboard/System-Management",
        element: <SystemManagement />,
        children: [
          {
            path: "/adminDashboard/System-Management/",
            element: <UpdateLogo />,
          },
          {
            path: "/adminDashboard/System-Management/configureServices",
            element: <ConfigureServices />,
          },
          {
            path: "/adminDashboard/System-Management/taxConfiguration",
            element: <TaxConfiguration />,
          },
          {
            path: "/adminDashboard/System-Management/salonConfiguration",
            element: <SalonConfiguration />,
          },
          {
            path: "/adminDashboard/System-Management/printerSettings",
            element: <PrinterSettings />,
          },
          {
            path: "/adminDashboard/System-Management/serviceCategories",
            element: <ServiceCategory />,
          },
          {
            path: "/adminDashboard/System-Management/paymentConfiguration",
            element: <Payment />,
          },
        ],
      },
      {
        path: "/adminDashboard/inventory-management",
        element: <InventoryManagement />,
        children: [
          {
            path: "/adminDashboard/inventory-management/",
            element: <Products />,
          },
          {
            path: "/adminDashboard/inventory-management/vendors",
            element: <Venders />,
          },
          {
            path: "/adminDashboard/inventory-management/salon-inventory",
            element: <SalonInventory />,
          },
        ],
      },
      {
        path: "/adminDashboard/Advertisement",
        element: <Advertisement />,
        children: [
          {
            path: "/adminDashboard/Advertisement",
            element: <PlatformAd />,
          },
        ],
      },
      {
        path: "/adminDashboard/Reports",
        element: <Reports />,
        children: [
          {
            path: "/adminDashboard/Reports/",
            element: <DayEndSummary />,
          },
        ],
      },
    ],
  },
];

const STAFF_ROUTES = [
  {
    path: "/scanner",
    element: <ThumbScanner />,
  },
  {
    path: "/employeeLogin",
    element: <EmployeeLogin />,
  },
];

const CUSTOMER_ROUTES = [
  {
    path: "/scanner",
    element: <ThumbScanner />,
  },
  {
    path: "/employeeLogin",
    element: <EmployeeLogin />,
  },
  {
    path: "/customer/signup",
    element: <SignUp />,
  },
  {
    path: "/customer/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/customer/service",
    element: <Service />,
  },
  {
    path: "/customer/service/male",
    element: <Service />,
  },
  {
    path: "/customer/service/female",
    element: <Service />,
  },
  {
    path: "/customer/service/kids",
    element: <Service />,
  },
  {
    path: "/customer/checkout",
    element: <Checkout />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
];

const protectedRoute = (role) => {
  switch (role) {
    case ROLE.ADMIN:
      return ADMIN_ROUTES;
    case ROLE.SUPER_ADMIN:
      return SUPER_ADMIN_ROUTES;
    case ROLE.STAFF:
      return STAFF_ROUTES;
    case ROLE.SALON:
      return CUSTOMER_ROUTES;
    default:
      return [];
  }
};

const routeList = (role) => [
  ...protectedRoute(role),
  // {
  //   path: "/salonOnboarding",
  //   element: <SuperAdminLogin />,
  // },
  {
    path: "/",
    element: <AdminLogin />,
  },
  // {
  //   path: "/",
  //   element: <Screensaver />,
  // },
  {
    path: "*",
    element: <AdminLogin />,
  },
  // {
  //   path: "/customer-signup",
  //   element: <SignUp />,
  // },
  {
    // path: "/login",
    // element: <StaffLogin />,
  },
];

export const routeBase = (role) => createBrowserRouter(routeList(role));
