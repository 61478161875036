export function mobileValidator(phoneNumber) {
  const isInteger = /^\d+$/.test(phoneNumber);
  const hasSpecificLength = phoneNumber.toString().length === 10;
  if (!phoneNumber)
    return { flag: false, message: "Mobile Number can't be empty." };
  if (!isInteger || !hasSpecificLength)
    return { flag: false, message: "Please Enter Valid Mobile Number" };
  return { flag: true, message: "" };
}

export function emailValidator(email) {
  const re = /\S+@\S+\.\S+/;
  if (!email) return { flag: false, message: "Email can't be empty." };
  if (!re.test(email))
    return { flag: false, message: "Ooops! We need a valid email address." };
  return { flag: true, message: "" };
}

export function isValidEmail(email) {
  if (!email) return false;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

export function passwordValidator(password) {
  if (!password) return { flag: false, message: "Password can't be empty." };
  if (password.length < 6)
    return {
      flag: false,
      message: "Password must be at least 6 characters long.",
    };

  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  // const hasSpecialChar = /[!@#$%^&*]/.test(password); // Add more special characters as needed

  if (!hasUppercase)
    return {
      flag: false,
      message: "Password must include at least one uppercase letter.",
    };
  if (!hasLowercase)
    return {
      flag: false,
      message: "Password must include at least one lowercase letter.",
    };
  if (!hasNumber)
    return {
      flag: false,
      message: "Password must include at least one number.",
    };
  // if (!hasSpecialChar) return {flag: false, message: 'Password must include at least one special character (!@#$%^&*).'}

  return { flag: true, message: "" };
}
