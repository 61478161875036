import { Typography } from "@mui/material";
import React from "react";

export const PlatformAd = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-[70vh] bg-gray-100">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Coming Soon</h1>
        <p className="text-lg text-gray-500 mb-8">
          This feature is currently under development.
        </p>

        <div className="w-full max-w-sm">
          <button className="block w-full p-4 text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600">
            Subscribe for Updates
          </button>
        </div>

        <div className="mt-8 text-gray-400">
          <p>Team smart-salon-bot </p>
         
        </div>
      </div>
    </div>
  );
};
