export const empConfigBody = (values) => {
  delete values.specialty;
  return {
    status: "UNHOLD",
    overAllRating: 5,
    checkedIn: true,
    isAvailable: false,
    password: "salon@123",
    ...values,
  };
};

export const imgUploadStaff = (id, img, salonId) => {
  return {
    userPhotoUrl: img,
    staffId: id,
  };
};
