import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Stack } from "@mui/material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface NoStaffModalProps {
  open: boolean;
  handleOpen: () => void;
}

const NoStaffModal: React.FC<NoStaffModalProps> = ({ open, handleOpen }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" color={"error"} component="h2">
              Service Currently Unavailable
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              This service is Currently unavailable. Please select another
              service.
            </Typography>
          <Stack direction={"row"} gap={2} mt={3} justifyContent={"end"}>
            <Button variant="contained" onClick={handleOpen}>Okay</Button>
          </Stack>
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default NoStaffModal;
