import { Button, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import {
  SERVICE_CATEGORY_FIELD_NAME,
  initialStateServiceCategory,
} from "./formModal/initialValueConfigService";
import { initialValueServiceCategory } from "./formModal/validationConfigService";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import { TextField } from "formik-mui";
import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";
import BadgeIcon from "@mui/icons-material/Badge";
import TransgenderIcon from "@mui/icons-material/Transgender";
import LabelIcon from "@mui/icons-material/Label";
import NotesIcon from "@mui/icons-material/Notes";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";
import { IServiceCategory } from "../types/configService";
import { SERVICE_CATEGORY_ENDPOINT } from "../../../../../../api/salon-api/salon-service";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import { requestBodyAddServiceCategory } from "./formModal/requestFact";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setAddServiceCategory } from "../../../../../../redux/feature/salonServiceSlice";
import {
  IDialogueModeServiceCategory,
  SERVICE_CATEGORY_FORM_MODE,
} from "../../ServiceCategory/ServiceCatTable";
import { useUpdateServicesCategory } from "../../ServiceCategory/configServiceCategoryApiHook";
import { RState } from "../../../../../../redux/feature/slice";
import { toast } from "react-toastify";

type AddServiceCategoryProps = {
  handleDialogueClose: () => void;
  openDialogueEditServiceCategory?: IDialogueModeServiceCategory;
};

const AddServiceCategory = ({
  handleDialogueClose,
  openDialogueEditServiceCategory,
}: AddServiceCategoryProps) => {
  const SalonDetails = useSelector(
    (state: RState) => state?.SalonDetails?.Salon_data
  );
  const { enqueueSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { updateServiceCategory, status } = useUpdateServicesCategory();

  // main function handling api request
  const handleSubmit = async (values: IServiceCategory) => {
    if (
      openDialogueEditServiceCategory?.mode === SERVICE_CATEGORY_FORM_MODE.EDIT
    ) {
      try {

        updateServiceCategory({
          ...values,
          id: openDialogueEditServiceCategory.data.id,
          gender: SalonDetails?.salonTypeStatus && SalonDetails?.salonTypeStatus !== "UNISEX"
          ? SalonDetails.salonTypeStatus.charAt(0) + SalonDetails.salonTypeStatus.slice(1).toLowerCase()
          : values.gender,
        });

        handleDialogueClose();
      } catch (error) {
        console.error("error", error);
      }
      return;
    }
    try {
      const requestBody = requestBodyAddServiceCategory({
        ...values,
        salonId: SalonDetails?.id,
        gender: SalonDetails?.salonTypeStatus && SalonDetails?.salonTypeStatus !== "UNISEX"
        ? SalonDetails.salonTypeStatus.charAt(0) + SalonDetails.salonTypeStatus.slice(1).toLowerCase()
        : values.gender,
      });
      const result = await axiosPrivate.post(
        SERVICE_CATEGORY_ENDPOINT,
        requestBody
      );
      dispatch(setAddServiceCategory(result.data));
      enqueueSnackbar("Service category added successfully", {
        variant: "success",
      });
      handleDialogueClose();
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <div>
      <Stack spacing={2}>
        <Formik
          initialValues={initialStateServiceCategory(
            openDialogueEditServiceCategory
          )}
          validationSchema={initialValueServiceCategory}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={true}
        >
          {() => {
            return (
              <Form id="add-service-category-form">
                <Stack py={1} flexDirection={"column"} spacing={2}>
                  <Stack direction={"row"} spacing={2}>
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter service category name"
                      label="Service Category Name"
                      type="text"
                      name={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_NAME}
                      id={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_NAME}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      // helperText="Please enter your name"
                      InputProps={FieldIcon(<BadgeIcon />)}
                    />

                    {SalonDetails?.salonTypeStatus === "UNISEX" && (
                      <Field
                        fullWidth
                        variant={MUI_VARIANT}
                        size={MUI_SIZE}
                        component={SelectField}
                        data={[
                          {
                            label: "Male",
                            value: "Male",
                          },
                          {
                            label: "Female",
                            value: "Female",
                          },
                        ]}
                        placeholder="Select service category gender"
                        label="Gender"
                        name={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_GENDER}
                        id={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_GENDER}
                        sx={{
                          height: MUI_TEXT_FIELD_HIGHT,
                        }}
                        Icon={TransgenderIcon}
                      />
                    )}
                  </Stack>
                  <Stack direction={"row"} spacing={2}>
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={SelectField}
                      data={[
                        {
                          label: "Active",
                          value: "ACTIVE",
                        },
                        {
                          label: "Inactive",
                          value: " INACTIVE",
                        },
                      ]}
                      placeholder="Select service category Status"
                      label="Service Status"
                      name={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_STATUS}
                      id={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_STATUS}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      Icon={LabelIcon}
                    />
                  </Stack>
                  <Stack>
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={TextField}
                      placeholder="Enter service category description"
                      label="Service category description"
                      type="text"
                      name={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_DESCRIPTION}
                      id={SERVICE_CATEGORY_FIELD_NAME.CATEGORY_DESCRIPTION}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      // helperText="Please enter your name"
                      InputProps={FieldIcon(<NotesIcon />)}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    <Button variant={"contained"} type="submit">
                      {openDialogueEditServiceCategory?.mode ===
                      SERVICE_CATEGORY_FORM_MODE.EDIT
                        ? "Update Service Category"
                        : "Add Service Category"}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </div>
  );
};

export default AddServiceCategory;
