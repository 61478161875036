import {
  IDialogueModeServiceCategory,
  SERVICE_CATEGORY_FORM_MODE,
} from "../../../ServiceCategory/ServiceCatTable";
import { IDialogueMode, SERVICE_FORM_MODE } from "../../ConfigServiceTable";
import {
  IRequestBodyAddService,
  IServiceCategory,
} from "../../types/configService";

export const CONFIG_SERVICE_FIELD_NAME = {
  SERVICE_NAME: "serviceName",
  SERVICE_TIME: "avgServeTime",
  SERVICE_GENDER: "gender",
  SERVICE_CATEGORY_ID: "serviceCategoryId",
  SALON_ID: "salonId",
  PRODUCT_CATEGORY_ID: "productCategoryId",
  SERVICE_DESCRIPTION: "description",
  SERVICE_PRICE: "price",
  PRODUCT_IDS: "productIdsList",
  IMG_UPLOAD: "imgUpload",
};

export const initialValueConfigService = (openDialogueEdit: IDialogueMode) => {
  const data =
    openDialogueEdit.mode === SERVICE_FORM_MODE.EDIT
      ? openDialogueEdit.data
      : null;

  return {
    serviceName: data?.serviceName || "",
    avgServeTime: data?.avgServeTime || 0,
    gender: data?.gender || "",
    serviceCategoryId: data?.serviceCategory.id || "",

    // salonId:  data?.salonId || 0,
    // ! Unsupported field need to remove in future
    productCategoryId: data?.productCategoryId || 1,
    description: data?.description || "",
    price: data?.price || 0,
    status: data?.status || "ACTIVE",

    productIdsList: data?.productsList 
      ? data?.productsList.map((ele) => {
      return ele.productId;
    }) : [],
    imgUpload: null,
    imageS3path: data?.imageS3path || "",
  } as IRequestBodyAddService;
};

export const SERVICE_CATEGORY_FIELD_NAME = {
  CATEGORY_NAME: "categoryName",
  CATEGORY_DESCRIPTION: "description",
  CATEGORY_STATUS: "status",
  CATEGORY_GENDER: "gender",
  CATEGORY_ID: "id",
};

export const initialStateServiceCategory = (
  openDialogueEditServiceCategory?: IDialogueModeServiceCategory
) => {
  console.log(
    openDialogueEditServiceCategory,
    "openDialogueEditServiceCategory"
  );
  const mode =
    openDialogueEditServiceCategory?.mode === SERVICE_CATEGORY_FORM_MODE.EDIT;
  return {
    categoryName: mode
      ? openDialogueEditServiceCategory?.data?.categoryName
      : "",
    description: mode ? openDialogueEditServiceCategory?.data?.description : "",
    status: mode ? openDialogueEditServiceCategory?.data?.status : "ACTIVE",
    gender: mode ? openDialogueEditServiceCategory?.data?.gender : "",
  } as IServiceCategory;
};
