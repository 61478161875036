import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "formik-mui";
import SelectField from "../../../components/Mui/Formik/SelectField";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { CREATE_ADMIN_ENDPOINT } from "../../../api/salon-api/salon-service";
import { useSelector } from "react-redux";
import CompleteMessage from "./CompleteMessage";
import { useNavigate } from "react-router-dom";
import { ADMIN_GENDER_TYPE } from "./ConstData";

function CreateAdminForm({ handleFinish, handleBack }) {
  const salonId = useSelector((state) => state.SalonDetails.Salon_data.id);
  const [isComplete, setIsComplete] = useState(false);
  const navigate  = useNavigate();

  async function handleSubmit(values) {
    try {
      await axios({
        method: "POST",
        url: CREATE_ADMIN_ENDPOINT,
        data: { ...values, userRole: 0, status: 0, salonId: salonId },
      });
      toast("Admin Created Successfully");
      setIsComplete(true);
      handleFinish();
      navigate('/')
    } catch (error) {
      console.error("Error Creating Admin:", error);
      toast("Error Creating Admin");
    }
  }

  const initialValues = {
    name: "",
    email: "",
    password: "",
    mobileNo: "",
    gender: 0,
  };

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    mobileNo: yup
      .string()
      .required("Required")
      .length(10, "Phone Number must be exactly 10 digits"),
    password: yup.string().required("Required"),
    gender: yup.string().required("Required"),
  });



  return isComplete ? (
    <CompleteMessage />
  ) : (
    <>
      <Typography component="h1" variant="body1" sx={{ textAlign: "center" }}>
        Please create an Admin to login to Admin Dashboard
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Box component="div" noValidate sx={{ my: 3 }}>
              <Divider sx={{ my: 4 }}>
                <Chip label="Create Admin" size="small" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    component={TextField}
                    helperText={touched.name ? errors.name : ""}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    component={TextField}
                    helperText={touched.email ? errors.email : ""}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    component={TextField}
                    helperText={touched.password ? errors.password : ""}
                    error={Boolean(touched.password && errors.password)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="mobileNo"
                    name="mobileNo"
                    label="Phone Number"
                    component={TextField}
                    helperText={touched.mobileNo ? errors.mobileNo : ""}
                    error={Boolean(touched.mobileNo && errors.mobileNo)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={SelectField}
                    name="gender"
                    label="Gender"
                    data={ADMIN_GENDER_TYPE}
                    helperText={touched.gender ? errors.gender : ""}
                    error={Boolean(touched.gender && errors.gender)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" sx={{ mb: 3, marginTop:3 }}>
                Create Admin
              </Button>
              {/* <Button
                onClick={handleBack}
                fullWidth
                variant="outlined"
                sx={{ mb: 3 }}
              >
                Back
              </Button> */}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateAdminForm;
