import React from 'react'
import Data from "../../../Data/categories/GroupCards.json"
import StarsIcon from '@mui/icons-material/Stars';

function GroupCards() {
    return (

        <div className='w-full my-12 p-2 flex flex-wrap flex-center'>

            {Data.map((data, index) =>
                <div key={index} className='pb-10'>
                    <p className='text-lg text-center sm:text-start sm:text-4xl  italic font-semibold my-10 sm:px-4'>{data.heading} </p>
                    <div className='w-full h-68 flex flex-center flex-wrap '>
                        {data.children.map((item, index) =>
                            <div key={index} className=' h-full w-1/3 sm:w-1/5 m-3 overflow-hidden bg-slate-50 transition duration-300 hover:-translate-y-3 flex-col shadow-md rounded-lg p-2'>
                                <div className='w-full h-2/3'><img src={item.imgSrc} className='rounded-lg img object-cover' /></div>
                                <div className='w-full h-1/3 p-1'>
                                    <p className='sm:text-xl text-xs font-semibold'>{item.service}</p>
                                    <div className='flex gap-1'>
                                        <StarsIcon className='text-xs sm:text-md' /><p className='italic sm:text-md text-xs font-md font-medium text-yellow-700'>{item.avgStar} ({item.volume})</p>
                                    </div>
                                    {item.price && <p className='sm:text-xl text-sm font-bold'>&#8377; {item.price}</p>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>

    )
}

export default GroupCards
