import React, { useState } from "react";
import { IConfigServiceData } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../redux/feature/slice";
import { usePaginatedServicesCategory } from "../../SalonStaff/Admin/SystemManagement/ServiceCategory/configServiceCategoryApiHook";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  ALL_SERVICE_BY_SERVICE_CATEGORY_ID,
  SERVICE_ALL_ENDPOINT,
} from "../../../api/salon-api/salon-service";
import { setServiceData } from "../../../redux/feature/salonServiceSlice";
import { usePaginatedServices } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/configServiceApiHook";

export interface IServiceDataLProps {
  serviceListData?: IConfigServiceData | null;
  type?: string | null;
}

function SelectService({ serviceListData, type }: IServiceDataLProps) {
  console.log("serviceListData", serviceListData);
  const serviceCategoryData = useSelector(
    (state: RState) => state?.services?.serviceCategories?.content
  );

  const PaginatedServicesCategory = usePaginatedServicesCategory();
  const dispatch = useDispatch();

  console.log("serviceCategoryData", serviceCategoryData);
  const axiosPrivate = useAxiosPrivate();

  const SalonDetails = useSelector(
    (state: RState) => state?.SalonDetails?.Salon_data
  );
  const serviceData = useSelector(
    (state: RState) => state.services.serviceData
  );

  const fetchService = async () => {
    try {
      const params = {
        // page: currentPage,
        // size: pageSize,
        salonId: SalonDetails?.id,
      };

      const response = await axiosPrivate.post<IConfigServiceData>(
        SERVICE_ALL_ENDPOINT,
        params
      );

      if (response.status === 200) {
        dispatch(setServiceData(response.data));
      } else {
        toast.warn("Failed to fetch services");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
    }
  };

  const handleFilterService = async (id: number | undefined) => {
    try {
      const result = await axiosPrivate({
        method: "GET",
        url: `${ALL_SERVICE_BY_SERVICE_CATEGORY_ID}/${id}`,
      });
      console.log("result", result);
      dispatch(
        setServiceData({
          totalElements: result.data.length,
          totalPages: 1,
          page: 1,
          content: result.data,
        })
      );
    } catch (error) {
      console.log("error ::", error);
      toast.warn("Unable to Filter Data");
    }
  };

  if (!serviceCategoryData) return null;
  return (
    <div className="border-2 border-gray-400 px-3 py-4 relative h-full w-full rounded-lg">
      <p className="absolute px-3 font-semibold italic -top-7 left-9 bg-slate-100 py-1 text-xl border-2 border-gray-400 rounded-lg">
        Select a Service
      </p>
      <div className="flex w-full flex-wrap gap-3">
        {serviceCategoryData
          .filter(
            (item) =>
              item.status === "ACTIVE" &&
              (SalonDetails?.salonTypeStatus.toUpperCase() === "UNISEX"
                ? item.gender.toLocaleUpperCase() === type?.toUpperCase()
                : item.gender.toUpperCase() === SalonDetails?.salonTypeStatus)
          )
          .map((item) => (
            <div
              key={item.id}
              className="flex items-center shadow-sm px-3 py-2 border-2 border-gray-400 rounded-lg bg-gray-900 text-white cursor-default"
              onClick={() => handleFilterService(item?.id)}
            >
              <div>{item.categoryName}</div>
              {/* <div
              className={
                item.serviceCategory.status === ("INACTIVE")
                  ? "text-red-500 m-1 w-2 h-2 rounded-full bg-red-500"
                  : ""
              }
            ></div> */}
            </div>
          ))}
        <div
          className="flex items-center shadow-sm px-3 py-2 border-2 border-gray-400 rounded-lg bg-gray-900 text-white cursor-default"
          onClick={fetchService}
        >
          <div>All</div>
        </div>
      </div>
    </div>
  );
}

export default SelectService;
