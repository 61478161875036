import React, { useState } from "react";
import {
  AppBar,
  Chip,
  Toolbar,
  Grid,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useDispatch, useSelector } from "react-redux";
import AddEmployeeForm from "./ui/AddEmployeeModal";
import { EmpConfigTable } from "./ui/EmpConfigTable";
import BaseCard from "../../../../../components/Mui/surfaces/BaseCard";
import { CustomizedDialog } from "../../../../../components/Mui/feedback/Dialog";
// icons
import PersonIcon from "@mui/icons-material/Person";
import { EMP_CONFIG_MODE } from "./constantEmpConfig";
import { Form, Formik } from "formik";
import { initialValueEmpConfig } from "./formModal/initialValueEmpConfig";
import { validationEmpConfig } from "./formModal/validationEmpConfig";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import {
  STAFF_ENDPOINT,
  UPLOAD_STAFF_ENDPOINT,
} from "../../../../../api/salon-api/salon-service";
import { empConfigBody, imgUploadStaff } from "./formModal/requestFact";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import {
  setEmployees,
  // setEmployeesData,
} from "../../../../../redux/feature/employeeSlice";
import { useUpdateEmployee } from "./configApiHook";
import { imageToBase64 } from "../../../../../helper/Common/uploader";

const initialSelectedData = {
  mode: EMP_CONFIG_MODE.ADD,
  data: {},
  isOpen: false, // for dialog
};

export default function ConfigureEmployees() {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(initialSelectedData);
  const { updateEmployee } = useUpdateEmployee(selectedData.data);
  const salonId = useSelector((state) => state.auth.data.salonId);

  const handleDialogClose = () => {
    setOpenDialog(false);
    handleModeAndData(EMP_CONFIG_MODE.ADD, {});
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleModeAndData = (mode, data) => {
    if (mode === EMP_CONFIG_MODE.EDIT) {
      setSelectedData({ mode: EMP_CONFIG_MODE.EDIT, data: data, isOpen: true });
      handleDialogOpen();
    }
    if (mode === EMP_CONFIG_MODE.ADD) {
      setSelectedData(initialSelectedData);
    }
  };

  const handleSubmit = async (values, action) => {
    const { setSubmitting } = action;
    setSubmitting(true);

    // console.log(values?.imgUpload, )
    // Update mode
    if (selectedData.mode === EMP_CONFIG_MODE.EDIT) {
      const img = await imageToBase64(values?.imgUpload);
      const imgSegment = img && img.split(",")[1];
      try {
        const data = {
          ...selectedData.data,
          password: "salon@123",
          ...values,
        };
        delete data?.specialty;
        await updateEmployee(data);
        if (img) {
          axiosPrivate({
            method: "PUT",
            url: UPLOAD_STAFF_ENDPOINT + `/${data.id}`,
            data: imgUploadStaff(data.id, imgSegment),
          });
        }
        setSubmitting(false);
        setOpenDialog(false);
      } catch (error) {
        // eslint-disable-next-line no-undef
        console.error("error", error);
      }
      return;
    }

    // ADD Mode
    try {
      const data = { ...values, salonId, isAvailable: true };
      const img = await imageToBase64(values.imgUpload);
      delete data?.specialty;
      const response = await axiosPrivate({
        method: "POST",
        url: STAFF_ENDPOINT,
        data: empConfigBody(data),
      });
      const imgSegment = img.split(",")[1];
      const responseImg = await axiosPrivate({
        method: "POST",
        url: UPLOAD_STAFF_ENDPOINT,
        data: imgUploadStaff(response.data.id, imgSegment),
      })
        .then((res) => {
          toast.success("Image Uploaded");
          return res.data;
        })
        .catch((err) => {
          toast.error("Error Uploading Image");
        });

      dispatch(setEmployees(response.data, selectedData));

      // upload img

      toast("Employee Added!");
      setSubmitting(false);
      setOpenDialog(false);
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.error("error", error);
      toast("Something went wrong!");
    }
  };

  return (
    <>
      <BaseCard>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <TableSearchBar handleDialogOpen={handleDialogOpen} />
        </AppBar>

        <Stack align="center" width={"100%"}>
          <EmpConfigTable handleModeAndData={handleModeAndData} />
        </Stack>
      </BaseCard>
      {openDialog && (
        <Formik
          initialValues={initialValueEmpConfig(selectedData)}
          validationSchema={validationEmpConfig}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={true}
        >
          {(formikProps) => {
            {
              console.log(formikProps, "formickprops");
            }
            return (
              <Form id="signup-form" className="w-full">
                <CustomizedDialog
                  openDialog={openDialog}
                  handleCloseDialog={handleDialogClose}
                  title={"Employee Details"}
                  icon={<PersonIcon />}
                  maxWidth="sm"
                  isActionButtonRequired={true}
                  actionButtons={
                    <ActionButton
                      isLoading={formikProps.isSubmitting}
                      formikProps={formikProps}
                    />
                  }
                >
                  <Stack my={1} spacing={2}>
                    <AddEmployeeForm formikProps={formikProps} />
                  </Stack>
                </CustomizedDialog>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

const ActionButton = ({ isLoading, formikProps }) => {
  return (
    <Stack>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        type="submit"
        form="signup-form"
        variant="contained"
        onClick={formikProps.submitForm}
      >
        Save
      </LoadingButton>
    </Stack>
  );
};

const TableSearchBar = ({ handleDialogOpen }) => {
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SearchIcon color="inherit" style={{ display: "block" }} />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              placeholder="Search by Name or Employee ID"
              InputProps={{
                disableUnderline: true,
                style: { fontSize: "default" },
              }}
              variant="standard"
              // onChange={(e) => setValue(e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <Chip
              label="Search"
              onClick={() => {
                // eslint-disable-next-line no-console, no-undef
                console.log("clicked");
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ marginRight: 1 }}
              onClick={handleDialogOpen}
            >
              Add Employee
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
