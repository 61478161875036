import { Field, FormikProps } from "formik";
import React, { useState } from "react";
import { InventoryContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { Stack } from "@mui/material";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";
import { INVENTORY_FIELD_NAMES } from "../formModal/inventoryInitialValue";
import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";
import { TextField } from "formik-mui";
import { usePaginatedProducts } from "../../../../../../hooks/api/inventory-service/useAllProduct";
import { usePaginatedVendorController } from "../../../../../../hooks/api/salonService/vendorController/useAllVendor";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScaleIcon from '@mui/icons-material/Scale';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


interface IProps {
  formikProps: FormikProps<InventoryContent>;
}
const InventoryFormUi = ({ formikProps }: IProps) => {
  const { allProducts, isLoading } = usePaginatedProducts(1, 1, true);
  const { vendorData, isLoading: isLoadingVendor } =
    usePaginatedVendorController(1, 1, true);

  // const [selectedProduct, setSelectedProduct] = useState<string>("");
  // console.log(selectedProduct);

  const productList =
    allProducts?.content?.map((product) => {
      return {
        value: product.id,
        label: product.name,
      };
    }) || null;

  const vendorList =
    vendorData?.content?.map((vendor) => {
      return {
        value: vendor.id,
        label: vendor.name,
      };
    }) || null;

  return (
    <Stack py={1} flexDirection={"column"} spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={productList}
          async={isLoading}
          placeholder="Select Product"
          label="Product"
          name={INVENTORY_FIELD_NAMES.productId}
          id={INVENTORY_FIELD_NAMES.productId}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          Icon={ShoppingCartIcon}
        />
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={vendorList}
          async={isLoadingVendor}
          placeholder="Select Vendor"
          label="Vendor"
          name={INVENTORY_FIELD_NAMES.vendorId}
          id={INVENTORY_FIELD_NAMES.vendorId}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          Icon={ShoppingBasketIcon}
        />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={[
            {
              label: "Milli-liter (ml)",
              value: "MILLILITER"
            },
            // {
            //   label: "Liter (l)",
            //   value: "LITER"
            // },
            {
              label: "Gram (gm)",
              value: "GRAM"
            }
            
          ]}
          async={true}
          placeholder="Select Measurement Unit"
          label="Measurement Unit"
          name={INVENTORY_FIELD_NAMES.measurementUnit}
          id={INVENTORY_FIELD_NAMES.measurementUnit}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          Icon={ScaleIcon}
        />
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          async={true}
          placeholder="Select Quantity"
          label="Quantity"
          name={INVENTORY_FIELD_NAMES.quantity}
          id={INVENTORY_FIELD_NAMES.quantity}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
            InputProps={FieldIcon(<ProductionQuantityLimitsIcon />)}
        />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          async={true}
          placeholder="Select Threshold"
          label="Threshold"
          name={INVENTORY_FIELD_NAMES.threshold}
          id={INVENTORY_FIELD_NAMES.threshold}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
            InputProps={FieldIcon(<DataThresholdingIcon />)}
        />
      </Stack>

      {/* <Stack direction={"row"} spacing={2}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          multiline={true}
          minRows={2}
          maxRows={3}
          placeholder="Enter product description"
          label="Description"
          type="text"
          name={INVENTORY_FIELD_NAMES.description}
          id={INVENTORY_FIELD_NAMES.description}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
            minRows: 3,
          }}
          InputProps={FieldIcon(<TextSnippetIcon />)}
        />
      </Stack> */}
    </Stack>
  );
};

export default InventoryFormUi;
