import React, { useState } from "react";
import PaymentWindow from "../../../components/CustomerComponent/Checkout/PaymentWindow";

function PaymentSummary({ TotalPrice }) {
  const [textOpen, setTextOpen] = useState(false);
  const [tipValue, setTipValue] = useState(0);

  function handleTextOpen() {
    setTextOpen(!textOpen);
  }

  function handleAddTip(value) {
    setTipValue(value);
  }

  const PROFESSIONAL_TIP = [
    {
      label: "30",
      value: 30,
      color: "lightGreen",
    },
    {
      label: "50",
      value: 50,
      color: "#60A5FA",
    },
    {
      label: "100",
      value: 100,
      color: "#22D3EE",
    },
  ];

  return (
    <div className=" w-full my-4 border-2 p-2 sm:p-8 border-gray-500 flex flex-col sm:flex-row justify-between items-center rounded-lg">
      <div className="h-full w-full sm:w-2/3 bg-slate-300 rounded-lg p-3 mb-3">
        <div className="w-full sm:h-2/3 flex flex-col justify-around px-6">
          <p className="sm:text-2xl font-semibold">Payment summary</p>
          <div>
            <div className="flex justify-between items-center text-sm sm:text-xl m-1 sm:font-medium">
              <p>Item total</p>
              {TotalPrice && <p>&#8377; {TotalPrice}</p>}
            </div>
            {/* <div className="flex justify-between items-center text-sm sm:text-xl m-1 sm:font-medium">
              <p>Discount</p>
              {Discount && <p>&#8377; {Discount}</p>}
            </div> */}
            {tipValue > 0 && (
              <div className="flex justify-between items-center text-sm sm:text-xl m-1 sm:font-medium">
                <p>Tip</p>
                {TotalPrice && <p>&#8377; {tipValue}</p>}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center text-sm sm:text-2xl m-1 sm:font-semibold">
            <p>Total</p>
            {TotalPrice && <p>&#8377; {TotalPrice + tipValue}</p>}
          </div>
        </div>
        <div className="w-full h-1/3 flex items-center justify-end pr-4">
          <PaymentWindow Tip={tipValue} TotalPrice={TotalPrice} />
        </div>
      </div>
      <div className="h-full w-full sm:w-1/3 sm:px-6 flex flex-col justify-between text-center sm:text-start">
        <p className="sm:text-2xl w-full min-h-1/6 font-semibold">
          Add a Tip to thank the Professional
        </p>
        <div className="sm:h-5/6 w-full text-center grid grid-rows-4 gap-2">
          {tipValue > 0 && (
            <p
              className="py-1 sm:font-semibold sm:text-2xl row-span-1 rounded-md shadow-md border-2 text-red-500 border-gray-400 cursor-default"
              onClick={() => setTipValue(0)}
            >
              Remove Tip
            </p>
          )}
          {PROFESSIONAL_TIP.map((item) => (
            <p
              className="py-1 sm:font-semibold sm:text-2xl row-span-1 rounded-md shadow-md cursor-default"
              style={{ backgroundColor: item.color }}
              onClick={() => handleAddTip(item.value)}
            >
              &#8377; {item.value}
            </p>
          ))}
          <p
            className="py-1 sm:font-semibold sm:text-2xl row-span-1 rounded-md shadow-md bg-yellow-400 cursor-default"
            onClick={handleTextOpen}
          >
            &#8377; custom
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentSummary;
