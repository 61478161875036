import React, { useEffect, useState } from "react";
import { onMessageListener, requestForToken } from "./firebase";
import { toast } from "react-toastify";

export const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const token = requestForToken();
  console.log("__token__", token);

  useEffect(() => {
    if (notification?.title) {
      toast.success("Notification received");
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return <ToastDisplay notification={notification} />;
};

function ToastDisplay({ notification }) {
  return (
    <div>
      <p>
        <b>{notification?.title}</b>
      </p>
      <p>{notification?.body}</p>
    </div>
  );
}
