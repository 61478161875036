import React from "react";
import Header from "../../../../components/SalonStaffComponent/Header";
import { toast } from "react-toastify";

export default function Advertisement() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const subTabs = [
    {
      label: "Platform Advertisement",
      to: "/adminDashboard/Advertisement",
      disable: true,
    },
  ];

  return (
    <Header
      onDrawerToggle={handleDrawerToggle}
      title="Advertisement"
      subTabs={subTabs}
    />
  );
}
